import React, { useEffect, useState, useContext } from "react";
import { HomeContext } from "../../store/global-context";
import classes from "./Hero.module.css"
import { MultipleClasses } from "../../helpers/Helpers";
import TypingHeader from "./TypingHeader";

import monitor from "../../assets/img/hero-n.webp"
import heroImg from "../../assets/img/hero.webp"
import Form from "../form/Form";

const Hero = (props) => {
    const ctx = useContext(HomeContext);

    let lastKnownScrollPosition = 0;
    let ticking = false;

    const [header3D, setHeader3D] = useState(1)
    const [headerOpacity, setHeaderOpacity] = useState(0)

    useEffect(() => {
        if (window.innerWidth < 780) return;

        function doSomething(scrollPos) {
            const percent = scrollPos / window.innerHeight;

            if (percent > .6) return;
            setHeader3D(1 - percent)
            setHeaderOpacity(percent * 2)

        }

        document.addEventListener('scroll', (e) => {
            lastKnownScrollPosition = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(() => {
                    doSomething(lastKnownScrollPosition);
                    ticking = false;
                });

                ticking = true;
            }
        });

    }, [lastKnownScrollPosition]);

    const openFormHandler = (e) => {
        e.preventDefault();
        ctx.setFormState(prev => {
            return {
                ...prev,
                isFormOpen: true
            }
        });
    }

    return (
        <div className={classes['hero-scroll-wrap']}>
            {ctx.formState.isFormOpen && <Form />}
            <div className={MultipleClasses(classes, ['sticky-wrap', 'sticky-wrap--hero'])}>
                <div className={classes['overflow-wrap']}>
                    <header className={classes['hero']}
                        style={{
                            transform:
                                `translate3d(0px, 0px, 0px) scale3d(${header3D}, ${header3D}, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)`,
                            transformStyle: "preserve-3d"
                        }}>
                        <img className={classes['hero-ui']}
                            alt="Imac"
                            sizes="100vw"
                            src={monitor}
                            srcSet={`${monitor} 500w, ${monitor} 800w, ${monitor} 2599w`}
                            style={{
                                opacity: headerOpacity
                            }} />
                        <div className={MultipleClasses(classes, ['container', 'container--hero'])}>
                            <div className={classes['hero-img-wrap']}>
                                <div className={classes['hero-img-container']}>
                                    <img className={classes['hero-img']}
                                        alt="img"
                                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 648px, (max-width: 991px) 100vw, 65vw"
                                        src={heroImg}
                                        srcSet={`${heroImg} 1200w`} />
                                </div>
                                <div id={classes['hero-img-shadow-1']}
                                    className={classes['hero-img-shadow-wrap']}>
                                    <div className={classes['hero-img-shadow']} />
                                </div>
                                <div id={classes['hero-img-shadow-2']}
                                    className={MultipleClasses(classes, ['hero-img-shadow-wrap', 'hero-img-shadow-wrap--intro'])}>
                                    <div className={classes['hero-img-shadow']} />
                                </div>
                            </div>
                            <div className={classes['hero__header']}>
                                <h2 className={classes['hero-eyebrow']}>Aley Elfadl</h2>
                                <div className={classes['hero-heading-ui-wrap']}>
                                    <div className={classes['hero-heading-ui']}>
                                        <div className={classes['hero-heading-ui-tab']}>
                                            <div className={classes['hero-heading-ui-tab-h1']}>H1</div>
                                            <div>hero heading</div>
                                        </div>
                                    </div>
                                    <div className={classes['hero-heading']}>
                                        <TypingHeader />
                                    </div>

                                </div>
                                <a id="a"
                                    className={MultipleClasses(classes, ['cta-link', 'cta-link--hero', 'ix--cta w-inline-block'])}
                                    onClick={openFormHandler}
                                    href="">
                                    <div className={classes['cta-link-text']}>
                                        Start a project request
                                    </div>
                                    <div
                                        className={MultipleClasses(classes, ['cta-link-icon', 'cta-link-icon--hero', 'w-embed'])}>
                                        <svg height="24"
                                            width="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 20L16 12L8 4"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="3.5" />
                                        </svg>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </header>
                </div>
            </div>
        </div>
    );
}

export default Hero;


