import React from "react";
import {HomeContextProvider} from "./store/global-context";
import './App.css';
import Hero from './components/hero/Hero';
import Cursor from './UI/Cursor'
import Section from './UI/Section';
import SectionHeader from './UI/SectionHeader';
import Benefits from "./components/benifits/Benefits";
import Projects from "./components/projects/Projects";
import Menu from "./components/menu/Menu";

function App() {

    return (
        <HomeContextProvider>
            <Menu>
                <Hero/>
            </Menu>
            <main className="main">
                <Cursor/>
                <Section extraClass='section--manifest'>
                    <SectionHeader h2Text={"World-class websites for ambitious companies."} isIntro={true}/>
                </Section>
                <Benefits/>
                <Projects/>
            </main>

        </HomeContextProvider>

    );
}

export default App;
