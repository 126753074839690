import classes from './FormInput.module.css';
import {MultipleClasses} from "../../helpers/Helpers";


const FormInput = (props) => {
    const {changed, id, isSelected, group, value, type} = props;
    return (
        <label htmlFor={id} className={classes['form-checkbox-field']} data-group={group}>
            <div className={MultipleClasses(classes, ['form-checkbox', `${isSelected ? 'checkbox-checked' : ''}`])}/>
            <input
                id={id}
                className={classes['checkbox-input']}
                onChange={changed}
                value={value}
                type={type}
                checked={isSelected}/>
            <span className={classes['form-checkbox-title']}>{value}</span>
        </label>
    );
};

export default FormInput