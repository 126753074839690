import React from 'react';
import classes from './Container.module.css';

const Container = (props) => {
    return (
        <div className={`${classes.container} ${classes['container--' + props.size]}`}>
            {props.children}
        </div>
    )
}

export default Container;