import classes from './FormTextInput.module.css';

const FormTextInput = (props) => {
    const {name, id, isOptional, isValid} = props;
    return (
        <div className={classes['form-field-wrap']}>
            <div className={`${!isValid && 'warning'} ${classes['form-field-label']}`}>{name} {isOptional && <span className={classes['form-field-label--optional']}>(optional)</span>}
            </div>
            <input type="text" spellCheck="false" className={classes['form-field-text']} maxLength="256" name={id} id={id} />
        </div>
    )
}

export default FormTextInput;