import React from "react";
import classes from './FormConfirmation.module.css'


const FormFail = () => {
    return (
        <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
        </div>
    )
}

const FormSuccess = () => {
    return (
        <div className="anfrage-form__success w-form-done">
            <div className="form-success-wrap">
                <div className="form-header">
                    <div className="form-eyebrow">Confirmation</div>
                    <h5 className="form-heading">Thanks for your request,
                        <span id="First-Name-value">{'{Name}'}</span>.</h5>
                    <p className="form-success-subheading">I will be in touch with you as soon as possible.
                        Due to
                        the high demand it can currently take up to 5 business days.</p>
                </div>
                <div id="anfrage__abbruch" data-w-id="4fee0ae0-1f41-a630-4ade-9d204db0073d"
                     className="anfrage-nav-button-wrap anfrage__abbruch--success anfrage-nav-button--abbruch">
                    <a id="a" href="#" className="cta-link cta-link--form w-inline-block">
                        <div className="cta-link-text">Close</div>
                    </a>
                </div>
            </div>
        </div>
    )
}

const FormConfirmation = (props) => {
    return (
        <>
            {props.success ? <FormSuccess/> : <FormFail/>}
        </>
    )
}

export default FormConfirmation;