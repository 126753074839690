import React from 'react';
import classes from './Grid.module.css';

const Grid = (props) => {
    return (
        <div className={`${classes['w-layout-grid']} ${props.isIntro ? classes['intro-grid'] : ''} ${classes[props.extraClass]}`}>
            {props.children}
        </div>
    )
}

export default Grid;