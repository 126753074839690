import React, {useState} from "react";

export const HomeContext = React.createContext({
    formState : {},
    setFormState () {},
})

export const HomeContextProvider = (props) => {
    const [formState, setFormState] = useState({
        isFormOpen: false,
    });

    return (
        <HomeContext.Provider value={{
            formState,
            setFormState,
        }}>
            {props.children}
        </HomeContext.Provider>
    )
}