import classes from './AnimationCard.module.css';
import {MultipleClasses} from "../../helpers/Helpers";
import Card from "../../UI/Card";

import display from '../../assets/img/605739cf94ca6b2bd3ae08c4_display.png'
import video from '../../assets/videos/animations.mp4'

const AnimationCard = () => {
    return (
        <Card extraClass={'intro-grid-cell--animation'}>
            <div>
                <h4 className={MultipleClasses(classes, ['intro-card-heading', 'intro-cell-heading--big'])}>
                    Stunning <br/>Animations.
                </h4>

                <p className={MultipleClasses(classes, ['intro-card-description', 'intro-card-description--animations'])}>
                    Whether it's subtle interactions that improve the UX, or complex "WOW" animations. I can build them all.
                </p>
            </div>
            <div className={classes['anim-video-wrap']}>
                <div className={classes['anim-video-container']}>
                    <img src={display}
                         alt="Apple Display" loading="lazy"
                         sizes="(max-width: 479px) 78vw, (max-width: 767px) 83vw, (max-width: 991px) 81vw, 42vw"
                         srcSet={`${display} 500w, ${display} 1182w`}
                         className={classes['anim-video-display']}/>

                    <div className={classes['w-embed']}>
                        <div className={MultipleClasses(classes, ['anim-video', 'w-background-video', 'w-background-video-atom'])}>
                            <video autoPlay={true} loop={true} muted={true} playsInline={true}>
                                <source src={video}/>
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default AnimationCard


