import React from 'react';
import classes from './CardHeading.module.css';
import {MultipleClasses} from "../helpers/Helpers";

const CardHeading = (props) => {
    return (
        <h4 className={`${classes['intro-card-heading']} ${MultipleClasses(classes, props.extraClass)}`}>{props.children}</h4>
    )
}

export default CardHeading;