import classes from './FormBtn.module.css'

const FormBtn = (props) => {
    return (
        <div onClick={props.clickHandler} className={classes['from-btn-wrapper']}>
            {props.type === 'Back' &&
                <svg width="7px" height="12px" viewBox="0 0 7 12"
                     version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
                       strokeLinecap="round" strokeLinejoin="round">
                        <g id="Artboard" transform="translate(-26.000000, -28.000000)" stroke="currentColor"
                           strokeWidth="2">
                            <polyline id="Path-2"
                                      transform="translate(29.500000, 34.000000) scale(-1, 1) translate(-29.500000, -34.000000) "
                                      points="27 39 32 34 27 29"/>
                        </g>
                    </g>
                </svg>}
            <button className={classes['form-nav-button']}>{props.type}</button>
        </div>
    )
}

export default FormBtn;