import React, {useState, useRef} from 'react';
import PortalReactDOM from 'react-dom'

import classes from './Project.module.css';
import {MultipleClasses} from "../../helpers/Helpers";

import closeSVG from '../../assets/icons/close.svg';


import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper";

import "swiper/css";
import "swiper/css/pagination";


const ProjectContent = (props) => {
    return (
        <div className={`${classes['project-list-item-container']} ${props.isOpen ? classes['opened'] : ''}`}
             onClick={props.openHandler}
             data-type={'open'}>
            <div id={'project-overlay-close-trigger'}
                 style={{'backgroundColor': 'hsla(0, 0.00%, 80.39%, 0.80);color:#444'}}
                 className={`${classes['project-item-close']} ${props.isOpen ? classes['rotate-btn'] : ''}`}>
                <div className={MultipleClasses(classes, ['project-item-close-icon', 'w-embed'])}
                     onClick={props.closeHandler}>
                    <img alt={'close'} src={closeSVG}/>
                </div>
            </div>
            <div className={classes['project-list-img-wrap']}>
                <div
                    className={MultipleClasses(classes, ['project-item-vid', 'w-embed', `${props.isOpen ? 'full-opacity' : ''}`])}>
                    <video width="100%" loop={false} muted={true} autoPlay={true}
                           playsInline={true} preload={"none"}>
                        {props.data.video && <source src={props.data.video} type="video/mp4"/>}
                    </video>
                </div>
                <img src={props.data.coverImage}
                     loading="lazy" alt=""
                     sizes="(max-width: 479px) 90vw, (max-width: 767px) 92vw, (max-width: 991px) 46vw, 47vw"
                     srcSet={`${props.data.coverImage} 1960w`}
                     className={classes['projects-list-img']}/>
            </div>
            <div className={`${classes['project-item-info-wrap']} ${props.isOpen ? classes['display-block'] : ''}`}>
                <div className={classes['project-info-header']}>
                    <div className={classes['project-services-wrap']}>
                        <div className={classes['project-service-label']}>{props.data.serviceLabel}</div>
                        <div
                            className={MultipleClasses(classes, ['project-services-dot', 'w-condition-invisible'])}></div>
                        <div
                            className={MultipleClasses(classes, ['project-service-label', 'w-condition-invisible'])}></div>
                    </div>

                    <h4 className={classes['project-title']}>{props.data.projectTitle}</h4>
                </div>

                <img
                    src={props.data.mainImage}
                    loading="lazy" alt="" sizes="100vw"
                    srcSet={`${props.data.mainImage}  500w, ${props.data.mainImage} 1960w`}/>

                <div className={classes['project-info-cta-wrap']}>
                    <a id="a" rel="noreferrer"
                       href={props.data.projectURL}
                       target="_blank"
                       className={MultipleClasses(classes, ['project-cta', 'w-inline-block'])}>
                        <div className={classes['cta-link-text']}>Open live site</div>
                    </a>
                </div>

                <div className={MultipleClasses(classes, ['swiper-container', 'w-dyn-list'])}>
                    {props.data.screens &&
                        <Swiper
                            modules={[Autoplay, Pagination]}
                            spaceBetween={64}
                            slidesPerView={1.8}
                            loop={true}
                            centeredSlides={true}
                            speed={800}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            className="mySwiper">
                            {
                                props.data.screens.map((screen, i) => {
                                    return (
                                        <SwiperSlide key={i}>
                                            <img key={`screen-${i}`}
                                                 src={screen}
                                                 loading="lazy"
                                                 alt="Screenshot of a website page" sizes="100vw"
                                                 srcSet={`${screen} 500w, ${screen} 800w, ${screen} 1000w`}
                                                 className={classes['project-slider-img']}/>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>}
                    <div className={MultipleClasses(classes, ['w-dyn-hide', 'w-dyn-empty'])}>
                        <div>No items found.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Project = (props) => {
    const projectListWrapRef = useRef(null);

    const [modalState, setModalState] = useState({
        initial: true,
        isOpen: false,
        position: {
            top : null,
            left : null,
        },
        size: {
            width : null,
            height : null,
        },
        padding: {
            top: window.innerWidth > 980 ? '64px' : '0px',
            bottom: window.innerWidth > 980 ? '64px' : '0px',
        },
    });

    let position = {}
    let size = {}

    let projectOverlay = document.getElementById('project-overlay');

    const openFSM = (e) => {
        if (e.currentTarget.dataset.type !== 'open' || modalState.isOpen) return;

        const projectListWrap = projectListWrapRef.current;
        const clientRect = projectListWrap.parentElement.parentElement.getBoundingClientRect();

        setModalState((prevState) => {
            return {
                ...prevState,
                isOpen: true,
                initial: false,
                position: {
                    // top: clientRect.top - document.body.getBoundingClientRect().top,
                    top: window.innerWidth > 980 ? clientRect.top - document.body.getBoundingClientRect().top : document.documentElement.scrollTop,
                    left: clientRect.left,
                },
                size: {
                    width: window.getComputedStyle(projectListWrap).width,
                    height: window.getComputedStyle(projectListWrap).height
                },
                padding: {
                    top: parseInt(window.getComputedStyle(projectListWrap).paddingTop),
                    bottom: parseInt(window.getComputedStyle(projectListWrap).paddingBottom),
                }
            }
        })

        position = {
            top: clientRect.top - document.body
                .getBoundingClientRect().top,
            left: clientRect.left
        }

        size = {
            width: window.getComputedStyle(projectListWrap).width,
            height: window.getComputedStyle(projectListWrap).height
        }


        projectOverlay.style.position = "absolute";
        projectOverlay.style.top = position.top + 'px';
        projectOverlay.style.left = position.left + 'px';
        projectOverlay.style.height = size.height;
        projectOverlay.style.width = size.width;
        projectOverlay.style.margin = projectListWrap.style.margin;
        document.body.style.overflow = 'hidden';

        setTimeout(() => {
            const classes = projectListWrap.classList.value.split(' ');
            for (let i = 0; i < classes.length; i++) {
                projectOverlay.classList.add(classes[i]);
            }

            projectOverlay.classList.add('growing');
            projectOverlay.style.height = '100vh';
            projectOverlay.style.width = '100%';
            projectOverlay.style.zIndex = '600';
            projectOverlay.style.top = window.pageYOffset + 'px';
            projectOverlay.style.left = '0';
            projectOverlay.style.margin = '0';

            projectOverlay.style.paddingTop = modalState.padding.top;
            projectOverlay.style.paddingBottom = modalState.padding.bottom;
            projectOverlay.classList.add('opened');
        }, 0);

        setTimeout(function () {
            projectOverlay.classList.remove('growing');
            projectOverlay.classList.add(classes['full-screen']);
        }, 500);
    };

    const closeFSM = (e) => {
        e.stopPropagation();
        if (!modalState.isOpen) return;

        setModalState((prevState) => {
            return {
                ...prevState,
                initial: false,
                position: {
                    top: 0,
                    left: 0,
                },
                size: {
                    width: 0,
                    height: 0
                },
                padding: {
                    top: 0,
                    bottom: 0,
                }
            }
        });

        projectOverlay.style.height = modalState.size.height;
        projectOverlay.style.width = modalState.size.width;
        projectOverlay.style.top = modalState.position.top + 'px';
        projectOverlay.style.left = modalState.position.left + 'px';
        projectOverlay.style.margin = '0';

        projectOverlay.classList.remove(classes['full-screen']);
        projectOverlay.classList.add('shrinking');

        projectOverlay.style.paddingTop = '0px';

        document.body.removeAttribute('style');
        projectOverlay.classList.remove('opened');

        setTimeout(function () {
            setModalState((prevState) => {
                return {
                    ...prevState,
                    isOpen: false,
                }
            });
            projectOverlay.setAttribute('class' , '');
            projectOverlay.setAttribute('style' , '');
        }, 500);

    };

    return (
        <div className={MultipleClasses(classes, ['projects-list-item', 'w-dyn-item'])}>
            <div className={MultipleClasses(classes, ['projects-list-item-link', 'lift'])}>
                <div ref={projectListWrapRef} className={MultipleClasses(classes, ['projects-list-item-wrap', 'fsm'])}>
                    <ProjectContent openHandler={openFSM} closeHandler={closeFSM} data={props.data}/>
                </div>
            </div>
            {modalState.isOpen &&
                PortalReactDOM.createPortal(<ProjectContent isOpen={modalState.isOpen} openHandler={openFSM} closeHandler={closeFSM} data={props.data}/>, document.getElementById('project-overlay'))}
        </div>
    )
}

export default Project;
