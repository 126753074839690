import imagesLoaded from 'imagesloaded';
// const { detect } = require('detect-browser');

export const classModuleWrapper = (module, className) => `.${module[className]}`;
export const MultipleClasses = (classes, classList) => {
    let extraClass = '';

    if (!Array.isArray(classList)) return classes[classList];
    for (let i = 0; i < classList.length; i++) {
        extraClass += ' ' + classes[classList[i]];
    }
    return extraClass.trim();

}

export const preloadImages = (selector = 'img') => {
    return new Promise((resolve) => {
        imagesLoaded(document.querySelectorAll(selector), {background: true}, resolve);
    });

};


// const browser = detect();
// export const isTouchDevices = browser.os === "Android OS" || browser.os === "iOS";
// export const isSafari = browser.name === "safari";
//
//
// export const getIndexPage = () => {
//     const pathNameMatch = window.location.pathname.match(/\d/);
//     return pathNameMatch !== null ? Number(pathNameMatch[0]) : 1;
// }
//
// const vhDevices = () => {
//     let vh = window.innerHeight * 0.01;
//     document.documentElement.style.setProperty('--vh', `${vh}px`);
// }
//
// export function utils() {
//     return new Promise(resolve => {
//         setTimeout(() => {
//             resolve();
//             vhDevices();
//             window.addEventListener('resize', () => { vhDevices(); });
//         }, 10);
//     });
// }
// var theme = localStorage.getItem('theme');
// var lightToggle = document.querySelector('#theme-light');
// var darkToggle = document.querySelector('#theme-dark');
//
// if (theme == "dark") {
//     document.body.setAttribute("data-theme", "dark");
//     darkToggle.classList.add("more-menu__link--active");
//     lightToggle.classList.remove("more-menu__link--active");
// }
//
// if (theme != "light" && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
//     document.body.setAttribute("data-theme", "dark");
//     darkToggle.classList.add("more-menu__link--active");
//     lightToggle.classList.remove("more-menu__link--active");
// }
// window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
//     if (event.matches) {
//         document.body.setAttribute("data-theme", "dark");
//         darkToggle.classList.add("more-menu__link--active");
//         lightToggle.classList.remove("more-menu__link--active");
//         localStorage.setItem('theme', 'dark');
//     } else {
//         document.body.removeAttribute("data-theme", "dark");
//         lightToggle.classList.add("more-menu__link--active");
//         darkToggle.classList.remove("more-menu__link--active");
//         localStorage.setItem('theme', 'light');
//     }
// });
// darkToggle.addEventListener("click", function () {
//     document.body.setAttribute("data-theme", "dark");
//     darkToggle.classList.add("more-menu__link--active");
//     lightToggle.classList.remove("more-menu__link--active");
//     localStorage.setItem('theme', 'dark');
//     fathom.trackGoal('6HZLT6XT', 0);
// });
//
// lightToggle.addEventListener("click", function () {
//     document.body.removeAttribute("data-theme", "dark");
//     lightToggle.classList.add("more-menu__link--active");
//     darkToggle.classList.remove("more-menu__link--active");
//     localStorage.setItem('theme', 'light');
//     fathom.trackGoal('6HZLT6XT', 0);
// });