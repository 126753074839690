import React from 'react';
import classes from './CleanCode.module.css';
import Card from "../../UI/Card";
import CardHeading from "../../UI/CardHeading";

const CleanCode = () => {
    return (
        <Card extraClass={['intro-card--tiny', 'cc-code']}>
            <CardHeading extraClass={'intro-card-heading--tiny'}>Clean Code</CardHeading>

            <div className={classes['intro-card-code-wrap']}>
                <div><span className={classes['code-color']}>&lt;ul&gt;</span></div>

                <div className={classes['intro-card-code-indented']}><span className={classes['code-color']}>&lt;li&gt;</span>Semantic
                    HTML.<span className={classes['code-color']}>&lt;/li&gt;</span></div>

                <div className="intro-card-code-indented"><span className={classes['code-color']}>&lt;li&gt;</span>Accessibility
                    done
                    right.<span className="code-color">&lt;/li&gt;</span></div>

                <div className={classes['intro-card-code-indented']}><span className={classes['code-color']}>&lt;li&gt;</span>Optimized
                    for performance.<span className={classes['code-color']}>&lt;/li&gt;</span></div>

                <div><span className={classes['code-color']}>&lt;/ul&gt;</span></div>
            </div>
        </Card>
    )
}

export default CleanCode;