import React from "react";
import classes from "./Section.module.css";

const Section = (props) => {
    // TODO: Better implementation
    const extraClass = classes[props.extraClass]
    return (
        <section id={props.id}
                 className={`${extraClass ? extraClass : ''} ${classes.section}`}>{props.children}</section>
    )
}

export default Section