import {useRef, useEffect} from "react";
import './Cursor.scss'

const Cursor = () => {

    const root = document.querySelector('html')
    const cursorRef = useRef()
    // translate: none;
    // rotate: none;
    // scale: none;
    // transform: translate(-50%, -50%);
    useEffect(() => {
        const cursor = cursorRef.current;

        root.addEventListener('mousemove', (e) => {
            cursor.style.left = `${e.clientX}px`
            cursor.style.top = `${e.clientY - 100}px`
        })

    }, []);

    return (
        <div ref={cursorRef} className="cursor" style={{
            translate : 'none',
            rotate : 'none',
            scale : 'none',
            transform : 'translate(-80%, -80%)',
            left : '50%',
            top : '50%',
        }}>
            <div className="colorOne"></div>
            <div className="colorTwo"></div>
            <div className="colorThree"></div>
        </div>
    )
}
export default Cursor;