import React from 'react';
import classes from './SectionHeader.module.css';

const SectionHeader = (props) => {
    const introStyling = props.isIntro ? classes['section-header--intro'] : '';
    const introH2Styling = `${classes['fluid-gradient-heading']} ${classes['fluid-gradient-heading--hero']} ${classes['cc-en']}`
    return (
        <div className={`${classes['section-header']} ${introStyling}`}>
            <h2 className={props.isIntro ? introH2Styling : classes['section-heading-eyebrow']}>
                {props.h2Text}
            </h2>
            {!props.isIntro &&
                <h3 className={classes['section-heading']}>
                    {props.h3Text}
                </h3>}
        </div>
    );
}

export default SectionHeader;