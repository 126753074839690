import React from "react";
import classes from './FormIntro.module.css';
import heroImg from "../../assets/img/hero.webp";
import {MultipleClasses} from "../../helpers/Helpers";

const FormIntro = React.forwardRef((props, ref) => {
    return (
        <div ref={ref} className={classes['form-intro']}>
            <div className={classes['form-header']}>
                <div className={classes['form-intro-img-wrap']}>
                    <div className={classes['hero-img-shadow']}>
                        <img
                            src={heroImg}
                            loading="lazy" alt="hero image" className={classes['form-intro-img']}/>
                    </div>
                </div>
                <h5 className={classes['form-heading']}>Project request</h5>
                <p className={classes['form-intro-text']}>
                    This form helps me to understand more quickly what your project
                    is about and clarifies important questions in advance. Therefore, please be as precise as possible
                    with your information, this saves us both time.
                </p>

                <div onClick={props.startClickHandler} className={classes['form-start-button']}>
                    <a id="a"
                       href="#"
                       className={MultipleClasses(classes, ['cta-link', 'cta-link--form'])}>
                        <span>Start</span>
                        <div className={MultipleClasses(classes, ['cta-link-icon', 'cta-link-icon--hero'])}>
                            <svg width="24" height="24"
                                 viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                 data-reactroot="">
                                <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="3.5"
                                      stroke="currentColor" d="M8 20L16 12L8 4"/>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
            <div className={classes['form-intro-bottom']}>
                <div>Hate forms?
                    <a href="mailto:alibinelfadl@gmail.com">alibinelfadl@gmail.com</a>
                </div>
            </div>
        </div>
    )
})

export default FormIntro;