import classes from './Benefits.module.css';

import Card from '../../UI/Card';
import Container from '../../UI/Container';
import Grid from '../../UI/Grid';
import Section from '../../UI/Section';
import SectionHeader from '../../UI/SectionHeader';

import StatsCard from './StatsCard';
import AnimationCard from './AnimationCard';
import VariationCard from './VariationCard';
import CleanCode from "./CleanCode";
import DevelopmentDuration from "./DevelopmentDuration";
import NewCard from "./NewCard";

import perfImg from '../../assets/img/610ec6af8b801cad3f77bf00_performance-screenshot.webp';
import integrationImg from '../../assets/img/610ec5cd51a74af461b46550_integrations.webp';
import responsiveImg from '../../assets/img/60fd7ed927e53b0052d7dcca_responsive-design.webp';

const Benefits = () => {
    return (
        <Section id='benefits' extraClass={'section--gray'}>
            <SectionHeader h2Text='Benefits' h3Text='Bring Your Ambitions To Life.' isIntro={false}/>
            <Container size={'large'}>

                <Grid isIntro={true}>
                    <Card extraClass={'pixel-perfect'}>
                        <h4 className="intro-card-heading">Pixel perfect websites</h4>
                        <p className="intro-card-description">I turn your designs into pixel-perfect websites with
                            exquisite animations and excellent attention to detail. Say good-bye to tense back-and-for
                            ths with developers that lack design taste..</p>
                    </Card>

                    <Grid isIntro={false} extraClass={'stats-grid'}>
                        <StatsCard type={'clients'} symbol={'plus'} figure={'25'} symbolValue={'+'}
                                   description={'Happy clients'}/>
                        <StatsCard type={'experience'} symbol={'text'} figure={'4'} symbolValue={'Years'}
                                   description={'Experience'}/>
                        <StatsCard type={'projects'} symbol={'plus'} figure={'35'} symbolValue={'+'}
                                   description={'Frontend projects'}/>
                        <StatsCard type={'passion'} symbol={'percent'} figure={'100'} symbolValue={'%'}
                                   description={'Passion'}/>
                    </Grid>

                    <AnimationCard/>

                    <VariationCard extraClass={['variation', 'intro-card--performance']}
                                   imageSrc={perfImg}
                                   imageAlt={'Screenshot of a website showing a performance score of 100 points'}
                                   imageClass={'intro-card-performance-img'}>Lightning fast loading times.</VariationCard>

                    <VariationCard extraClass={['variation', 'intro-card--integrations']}
                                   imageSrc={integrationImg}
                                   imageAlt={'Integrations'}
                                   imageClass={'intro-card-integrations-img'}>Latest<br/>Technologies.</VariationCard>

                </Grid>

                <Grid isIntro={true} extraClass={'intro-grid--variant'}>
                    <VariationCard hasNoImage={true} extraClass={['variation', 'intro-card--pixel']}>
                        <span className={classes['intro-card-pixel-perfect']}>Pixel-Perfect</span><br/>Development.
                    </VariationCard>

                    <VariationCard extraClass={['variation', 'intro-card--responsive']}
                                   imageSrc={responsiveImg}
                                   imageAlt={'A website opened on desktop, tablet and mobile'}
                                   imageClass={'intro-card-responsive-img'}>Responsive Design.</VariationCard>
                </Grid>
                <Grid isIntro={true}>
                    {/*<NewCard />*/}
                    <CleanCode />
                    <DevelopmentDuration />
                </Grid>
                <Grid isIntro={true}>
                    <NewCard />
                </Grid>
            </Container>
        </Section>
    )
}

export default Benefits
