import React from "react";
import classes from "./FormSlide.module.css";
import {MultipleClasses} from "../../helpers/Helpers";

const FormSlide = (props) => {
    const type = props.isCheckboxes ? 'form-checkbox-wrap' : 'form-field-wrap';

    const isHidden = props.isHidden ? 'hidden' : '';
    return (
        <>
            <div className={`${isHidden} ${MultipleClasses(classes, ['form-slide-content', 'slide-in-bottom__lite'])}`} data-slide-number={props.slideNumber}>
                <div className="form-header">
                    <div className="form-eyebrow">{props.eyebrow}</div>
                    <h6 className="form-heading">{props.heading}</h6>
                </div>
                <div className={type}>
                    {props.children}
                </div>
            </div>
        </>
    )
}

export default FormSlide