import classes from './FormProgress.module.css';
import React from "react";

const FormProgress = (props) => {
    const items = Array(props.steps).fill('')

    return (
        <div className="form-progress">
            {
                items.map((step, index) => index < props.completedSteps ?
                    <div key={index} className="form-progress-step form-progress-step--filled"/> :
                    <div key={index} className="form-progress-step"/>)
            }
        </div>
    )
}

export default FormProgress;