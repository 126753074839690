import React from 'react';
import classes from './VariationCard.module.css';
import Card from "../../UI/Card";
import CardHeading from "../../UI/CardHeading";

const VariationCard = (props) => {

    const {hasNoImage, imageSrc, imageAlt, imageClass, extraClass} = props;

    return (
        <Card extraClass={extraClass}>
            <CardHeading>{props.children}</CardHeading>
            {!hasNoImage &&
                <img src={imageSrc}
                     loading="lazy"
                     srcSet={`${imageSrc} 500w, ${imageSrc} 1188w`}
                     sizes="(max-width: 479px) 86vw, (max-width: 767px) 79vw, 600px"
                     alt={imageAlt}
                     className={imageClass}/>}
        </Card>

    )
}

export default VariationCard;