import React from "react";
import classes from "./Card.module.css";
import {MultipleClasses} from "../helpers/Helpers";

const Card = React.forwardRef((props, ref) => {
    const classNames = Array.isArray(props.extraClass) ? [...props.extraClass, 'intro-card'] : [props.extraClass, 'intro-card'];
    return (
        <div ref={ref} id={props.id} className={MultipleClasses(classes, classNames)}>
            {props.children}
        </div>
    );
})

export default Card;
