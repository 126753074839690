import classes from './FormTextArea.module.css';

const FormTextArea = (props) => {
    const {id, label, isValid} = props;
    return (
        <div className={classes['form-field-wrap']}>
            <div className={`${!isValid && 'warning'} ${classes['form-field-label']}`}>{label}</div>
            <textarea name={label} maxLength="5000" id={id} required={true}
                      spellCheck="false" className={classes['form-field-area']}/>
        </div>
    )
}

export default FormTextArea;