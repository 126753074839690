import React from 'react';
import classes from './Projects.module.css';
import Container from '../../UI/Container';
import Section from '../../UI/Section';
import SectionHeader from '../../UI/SectionHeader';
import Project from "./Project";

import nutritherapyVid from '../../assets/videos/nutritherapy-video.mp4';
import nutritherapyCover from '../../assets/img/nutritherapy-cover.webp';
import nutritherapyMain from '../../assets/img/nutritherapy-phone.webp';
import nutritherapyScreen1 from '../../assets/img/nutritherapy-website-desktop.webp';
import nutritherapyScreen2 from '../../assets/img/nutritherapy-website-desktop-2.webp';

import outlineVid from '../../assets/videos/website.mp4'
import outlineCover from '../../assets/img/outline-cover.png'
import outlineMain from '../../assets/img/outline-website-phone.webp'
import outlineScreen1 from '../../assets/img/outline-website.webp'
import outlineScreen2 from '../../assets/img/outline-website-2.webp'

import outlineDBCover from '../../assets/img/outline admin.png'
import outlineDBMain from '../../assets/img/outlineDB-mobile.webp'
import outlineDBMScreen1 from '../../assets/img/outline-DB-1.webp'
import outlineDBMScreen2 from '../../assets/img/outline-DB-2.png'
import outlineDBMScreen3 from '../../assets/img/outline-DB-3.webp'
import outlineDBMScreen4 from '../../assets/img/outline-DB-4.webp'

import highBeautyCover from '../../assets/img/highbeautycover.png'
import highBeautyMain from '../../assets/img/highbeautyphone.webp'

const Projects = () => {

    const projectData = [
        {
            projectTitle: 'Nutritherapy',
            serviceLabel: 'Web Development',
            projectURL: '#',
            coverImage: nutritherapyCover,
            mainImage: nutritherapyMain,
            video: nutritherapyVid,
            screens: [nutritherapyScreen1, nutritherapyScreen2]
        },
        {
            projectTitle: 'Outline',
            serviceLabel: 'Web Development',
            projectURL: '#',
            coverImage: outlineCover,
            mainImage: outlineMain,
            video: outlineVid,
            screens: [outlineScreen1, outlineScreen2]
        },
        {
            projectTitle: 'Outline Dashboard',
            serviceLabel: 'Web Development',
            projectURL: '#',
            coverImage: outlineDBCover,
            mainImage: outlineDBMain,
            video: null,
            screens: [outlineDBMScreen1, outlineDBMScreen2, outlineDBMScreen3, outlineDBMScreen4]
        },
        {
            projectTitle: 'HighBeauty',
            serviceLabel: 'Web Design',
            projectURL: '#',
            coverImage: highBeautyCover,
            mainImage: highBeautyMain,
            video: null,
            screens: null
        }
    ]

    return (
        <Section id='projects'>
            <SectionHeader h2Text='Projects' h3Text='A small selection of my projects.' isIntro={false}/>
            <Container size={'large'}>
                <div className={classes['projects-wrap']}>
                    <div className="projects-list-wrapper w-dyn-list">
                        <div role="list" className="projects-list w-dyn-items">
                            <Project data={projectData[0]}/>
                            <Project data={projectData[1]}/>
                            <Project data={projectData[2]}/>
                            <Project data={projectData[3]}/>
                        </div>
                    </div>
                    <div style={{opacity: 0}} className={classes['project-overlay']}>
                        <div className={classes['project-overlay-close-trigger']}/>
                    </div>
                </div>
            </Container>
        </Section>
    )
}

export default Projects

