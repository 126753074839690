import React from 'react';
import Card from '../../UI/Card';
import classes from './StatsCard.module.css';

const StatsCard = (props) => {
    const {type, symbol, figure, symbolValue, description} = props;

    const typeClass = classes['stats-figure-wrap--' + type]
    const symbolClass = classes['intro-stats-symbol--' + symbol]
    return (
        <Card extraClass={'intro-card--stats-cell'}>
            <div className={`${classes['stats-figure-wrap']} ${typeClass}`}>
                <div className={classes['intro-stats-figure']}>{figure}</div>
                <div className={`${classes['intro-stats-symbol']} ${symbolClass}`}>{symbolValue}</div>
            </div>
            <div className={classes['stats-description']}>{description}</div>
        </Card>
    )
}
export default StatsCard;