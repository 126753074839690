import React, {useRef, useState, useContext, useEffect} from "react";
import {HomeContext} from "../../store/global-context";
import useInput from "../../hooks/use-input";

import classes from "./Form.module.css";
import {MultipleClasses} from "../../helpers/Helpers";
import './Form.css';

import FormIntro from "./FormIntro";
import FormBtn from "../../UI/form/FormBtn";
import FormNextBtn from "../../UI/form/FormNextBtn";

import FormSlide from "./FormSlide";
import FormConfirmation from "./FormConfirmation";
import FormInput from "../../UI/form/FormInput";
import FormTextInput from "../../UI/form/FormTextInput";
import FormTextArea from "../../UI/form/FormTextArea";
import FormProgress from "./FormProgress";
import {default as ConsentCheckbox} from "../../UI/form/FormConsentCheckbox";

const Form = () => {
    const [formState, setFormState] = useState({
        currentSlide: 0,
        totalSlides: 7,
        isValid: false,
        isTouched: false,
    });

    const [formData, setFormData] = useState({
        services: {
            values: [],
            isValid: false
        },
        companyAge: {
            value: '',
            isValid: false
        },
        projectTimeline: {
            value: '',
            isValid: false
        },
        projectBudget: {
            value: '',
            isValid: false
        },
        companyInfo: {
            value: '',
            isValid: false
        },
        projectDescription: {
            value: '',
            isValid: false
        },
        contactInfo: {
            value: '',
            isValid: false
        }
    })
    const ctx = useContext(HomeContext);

    const formWrapperRef = useRef();
    const isIntro = formState.currentSlide === 0;

    const cancelBtnHandler = (e) => {

        const formContainer = formWrapperRef.current;
        formContainer.firstChild.classList.add('slide-out-bottom');

        formContainer.classList.add('slide-out-bottom-2');

        setTimeout(() => {
            formContainer.classList.remove('slide-in-bottom-2');
            ctx.setFormState((pervState) => {
                return {
                    ...pervState,
                    isFormOpen: false
                }
            });
        }, 200);

    };

    const radioHandler = (e) => {
        // check which group does the radio belongs to
        const group = e.target.closest('label').dataset.group;
        console.log(group)

        setFormData(state => {
            // update that group's state
            const newState = {...state}
            newState[group] = {
                value: e.target.value
            }
            return newState;
        });
    };

    const checkboxHandler = (e) => {
        const value = e.target.value;

        const helper = (servicesArr) => {
            // checks if the clicked item is already added
            if (servicesArr.includes(value) && !e.target.checked) {
                // removes it if true
                return servicesArr.filter((val) => val !== value);
            } else return [...servicesArr, e.target.value] // add the item if it is not already added
        }

        setFormData(state => {
            return {
                ...state,
                services: {
                    values: helper(formData.services.values)
                }
            }
        });
    };

    const nextBtnHandler = (e) => {

        if (formState.currentSlide === 0) {
            setFormState(pervState => {
                return {
                    ...pervState,
                    currentSlide: 1,
                }
            })
            return;
        }

        // if (!formState.isValid) return;
        if (formState.currentSlide === formState.totalSlides) return;

        setFormState(prevState => {
            return {
                ...prevState,
                currentSlide: prevState.currentSlide++,
            };
        });

    }

    const backBtnHandler = (e) => {
        if (formState.currentSlide === 0) return;

        setFormState(prevState => {
            return {
                ...prevState,
                currentSlide: prevState.currentSlide--,
            };
        });

    }

    return (
        <div className={classes['form-overlay-wrap']}>
            <div ref={formWrapperRef} className={classes['form-wrap']}>

                <div className={classes['form-header']}>
                    {!isIntro && <FormBtn type={'Back'} clickHandler={backBtnHandler}/>}
                    {!isIntro && <FormProgress steps={formState.totalSlides} completedSteps={formState.currentSlide}/>}
                    <FormBtn type={'Cancel'} clickHandler={cancelBtnHandler}/>
                </div>

                {isIntro && <FormIntro startClickHandler={nextBtnHandler}/>}

                <div className={MultipleClasses(classes, ['contact-form-block', `${isIntro && 'hidden'}`])}>
                    <form id="contact-form" name="contact-form" className={classes['contact-form']}>

                        <FormSlide isCheckboxes={true} eyebrow={'Services'} isHidden={formState.currentSlide !== 1}
                                   heading={'Which services do you need?'}>
                            <FormInput id="web-dev" type='checkbox' value={'Web Development'} changed={checkboxHandler}
                                       isSelected={formData.services.values.includes('Web Development')}/>
                            <FormInput id="web-des" type='checkbox' value={'Web Design'} changed={checkboxHandler}
                                       isSelected={formData.services.values.includes('Web Design')}/>
                            <FormInput id="other" type='checkbox' value={'Other'} changed={checkboxHandler}
                                       isSelected={formData.services.values.includes('Other')}/>
                        </FormSlide>

                        <FormSlide isCheckboxes={true} eyebrow={'Age'} isHidden={formState.currentSlide !== 2}
                                   heading={'What\'s the age of your company?'}>
                            <FormInput id="pre-launch" type="radio" value="Pre-launch" changed={radioHandler}
                                       group={'companyAge'} isSelected={formData.companyAge.value === 'Pre-launch'}/>
                            <FormInput id="new" type="radio" value="0-5 Years" changed={radioHandler}
                                       group={'companyAge'} isSelected={formData.companyAge.value === '0-5 Years'}/>
                            <FormInput id="mid" type="radio" value="5-10 Years" changed={radioHandler}
                                       group={'companyAge'} isSelected={formData.companyAge.value === '5-10 Years'}/>
                            <FormInput id="established" type="radio" value="10+ Years" changed={radioHandler}
                                       group={'companyAge'} isSelected={formData.companyAge.value === '10+ Years'}/>
                        </FormSlide>

                        <FormSlide isCheckboxes={false} eyebrow={'Timeline'} isHidden={formState.currentSlide !== 3}
                                   heading={'Do you have a timeline?'}>
                            <FormTextInput name={'Start'} id={'start'} isOptional={true}/>
                            <FormTextInput name={'End'} id={'end'} isOptional={true}/>
                        </FormSlide>

                        <FormSlide isCheckboxes={true} eyebrow={'Budget'} isHidden={formState.currentSlide !== 4}
                                   heading={'What is the budget range for the project?'}>
                            <FormInput id="2-5" type='radio' value={'2.000 - 5.000 SAR'} changed={radioHandler}
                                       group={'projectBudget'}
                                       isSelected={formData.projectBudget.value.includes('2.000 - 5.000 SAR')}/>
                            <FormInput id="5-10" type='radio' value={'5.000 - 10.000 SAR'} changed={radioHandler}
                                       group={'projectBudget'}
                                       isSelected={formData.projectBudget.value.includes('5.000 - 10.000 SAR')}/>
                            <FormInput id="10-20" type='radio' value={'10.000 - 20.000 SAR'} changed={radioHandler}
                                       group={'projectBudget'}
                                       isSelected={formData.projectBudget.value.includes('10.000 - 20.000 SAR')}/>
                            <FormInput id="20+" type='radio' value={'20.000+ SAR'} changed={radioHandler}
                                       group={'projectBudget'}
                                       isSelected={formData.projectBudget.value.includes('20.000+ SAR')}/>
                        </FormSlide>

                        <FormSlide isCheckboxes={false} eyebrow={'Company'} isHidden={formState.currentSlide !== 5}
                                   heading={'About your company'}>
                            <FormTextInput name={'Company name'} id={'company-name'} isOptional={false}/>
                            <FormTextInput name={'Your position'} id={'position'} isOptional={true}/>
                            <FormTextInput name={'Website URL'} id={'company-url'} isOptional={true}/>
                        </FormSlide>

                        <FormSlide isCheckboxes={false} eyebrow={'Project'} isHidden={formState.currentSlide !== 6}
                                   heading={'What is the project about?'}>
                            <FormTextArea isValid={true} id={'project-info'} label={'Project description'}/>
                        </FormSlide>

                        <FormSlide isCheckboxes={false} eyebrow={'About you'} isHidden={formState.currentSlide !== 7}
                                   heading={'How can I contact you?'}>
                            <FormTextInput name={'First name'} id={'first-name'} isOptional={false}/>
                            <FormTextInput name={'Last name'} id={'last-name'} isOptional={false}/>
                            <FormTextInput name={'Email'} id={'email'} isOptional={false}/>
                            <ConsentCheckbox/>
                        </FormSlide>

                    </form>
                    <FormConfirmation success={true}/>
                    <FormConfirmation success={false}/>
                </div>

                {!isIntro && <div className={`${formState.isValid && classes['disabled']} ${classes['form-footer']}`}>
                    <FormNextBtn clickHandler={nextBtnHandler}/></div>}

            </div>
        </div>
    )
}

export default Form;