import {useState} from "react";
import classes from "./DarkModeToggle.module.css";


const DarkModeToggle = () => {
    const [_, setIsDark] = useState(true)
    const savedTheme = localStorage.getItem('theme');

    if (savedTheme) {
        document.body.setAttribute("data-theme", savedTheme);
    } else document.body.setAttribute("data-theme", 'dark');

    const currentTheme = document.body.getAttribute("data-theme");

    const handleChange = event => {

        if (event.currentTarget.checked) {
            document.body.setAttribute("data-theme", "dark")
            localStorage.setItem('theme', "dark");
        } else {
            document.body.setAttribute("data-theme", "light")
            localStorage.setItem('theme', "light");
        }
        setIsDark(current => !current);
    };

    return <input id="toggle"
                  className={classes[currentTheme === 'dark' ? 'moon' : 'sun']}
                  type="checkbox"
                  checked={currentTheme === 'dark'}
                  onChange={handleChange}/>
}

export default DarkModeToggle

