import {useRef, useEffect, useState} from "react";
import classes from "./Menu.module.css";
import {MultipleClasses} from "../../helpers/Helpers";
import {preloadImages} from '../../helpers/Helpers';
import {gsap} from 'gsap';
import DarkModeToggle from "./DarkModeToggle";

import img1 from '../../assets/img/1.jpg'
import img2 from '../../assets/img/5.jpg'
import img3 from '../../assets/img/3.jpg'
import img4 from '../../assets/img/8.jpg'
import img5 from '../../assets/img/5.jpg'
import img6 from '../../assets/img/6.jpg'
import img7 from '../../assets/img/3.jpg'
import img8 from '../../assets/img/8.jpg'
import img9 from '../../assets/img/1.jpg'

const Menu = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const mainWrapperRef = useRef()
    const overlayPathRef = useRef()
    let isAnimating = false;

    useEffect(() => {
        preloadImages(`.${classes['tiles__line-img']}`).then(() => console.log('loaded'));
        const ctx = gsap.context(() => {
            const frame = document.querySelector(`.${classes.frame}`);
            const menuWrap = document.querySelector(`.${classes['menu-wrap']}`);
            const overlayPath = overlayPathRef.current;

            if (!isMenuOpen) {
                document.body.removeAttribute('style');

                if (isAnimating) return;

                isAnimating = true;

                gsap.timeline({
                    onComplete: () => isAnimating = false
                }).set(overlayPath, {
                        attr: {d: 'M 0 0 V 0 Q 50 0 100 0 V 0 z'}
                    })
                    .to(overlayPath,{
                        duration: 0.8,
                        ease: 'power4.in',
                        attr: {d: 'M 0 0 V 50 Q 50 100 100 50 V 0 z'}
                    }, 0)
                    .to(overlayPath, {
                        duration: 0.3,
                        ease: 'power2',
                        attr: {d: 'M 0 0 V 100 Q 50 100 100 100 V 0 z'},
                        onComplete: () => {
                            frame.classList.remove(classes['frame--menu-open']);
                            menuWrap.classList.remove(classes['menu-wrap--open']);
                        }
                    })
                    // now reveal
                    .set(overlayPath, {
                        attr: {d: 'M 0 100 V 0 Q 50 0 100 0 V 100 z'}
                    })
                    .to(overlayPath, {
                        duration: 0.3,
                        ease: 'power2.in',
                        attr: {d: 'M 0 100 V 50 Q 50 100 100 50 V 100 z'}
                    })
                    .to(overlayPath, {
                        duration: 0.8,
                        ease: 'power4',
                        attr: {d: 'M 0 100 V 100 Q 50 100 100 100 V 100 z'}
                    })
                    // title elements
                    .to(`.${classes.content}`, {
                        duration: 1.1,
                        ease: 'power4',
                        y: 0,
                        stagger: -0.05
                    }, '>-=1.1')

                    // menu items translate animation
                    .to(`.${classes['menu__item']}`, {
                        duration: 0.8,
                        ease: 'power2.in',
                        y: 100,
                        opacity: 1,
                        stagger: -0.05
                    }, 0)
            } else {
                document.body.style.overflow = 'hidden';

                if (isAnimating) return;
                isAnimating = true;

                gsap.timeline({
                    onComplete: () => isAnimating = false
                })
                    .set(overlayPath, {
                        attr: {d: 'M 0 100 V 100 Q 50 100 100 100 V 100 z'}
                    })
                    .to(overlayPath, {
                        duration: 0.8,
                        ease: 'power4.in',
                        attr: {d: 'M 0 100 V 50 Q 50 0 100 50 V 100 z'}
                    }, 0)
                    .to(overlayPath, {
                        duration: 0.3,
                        ease: 'power2',
                        attr: {d: 'M 0 100 V 0 Q 50 0 100 0 V 100 z'},
                        onComplete: () => {
                            frame.classList.add(classes['frame--menu-open']);
                            menuWrap.classList.add(classes['menu-wrap--open']);
                        }
                    })
                    // title elements
                    .to(`.${classes.content}`, {
                        duration: 0.8,
                        ease: 'power3.in',
                        y: -200,
                        stagger: 0.05
                    }, 0.2)

                    // now reveal
                    .set(`.${classes['menu__item']}`, {
                        opacity: 0
                    })
                    .set(overlayPath, {
                        attr: {d: 'M 0 0 V 100 Q 50 100 100 100 V 0 z'}
                    })
                    .to(overlayPath, {
                        duration: 0.3,
                        ease: 'power2.in',
                        attr: {d: 'M 0 0 V 50 Q 50 0 100 50 V 0 z'}
                    })
                    .to(overlayPath, {
                        duration: 0.8,
                        ease: 'power4',
                        attr: {d: 'M 0 0 V 0 Q 50 0 100 0 V 0 z'}
                    })
                    // menu items translate animation
                    .to(`.${classes['menu__item']}`, {
                        duration: 1.1,
                        ease: 'power4',
                        startAt: {y: 150},
                        y: 0,
                        opacity: 1,
                        stagger: 0.05
                    }, '>-=1.1');
            }
        }, mainWrapperRef.current);
        return () => ctx.revert();
    }, [isMenuOpen]);


    return (
        <main ref={mainWrapperRef} className={classes['menu-main']}>
            <div className={classes.frame}>
                <div className={classes['frame__author']}>
                    <button className={classes.unbutton} aria-label="Open menu">
                        <DarkModeToggle/>
                    </button>
                </div>
                <div className={classes['frame__button']}>
                    <button onClick={() => setIsMenuOpen(true)} className={MultipleClasses(classes, ['unbutton', 'button-menu'])} aria-label="Open menu">
                        <svg width="19" height="12" viewBox="0 0 19 12">
                            <path
                                d="m.742 3.26.485.874c.043-.024.13-.07.26-.136.22-.11.476-.233.765-.361A22.92 22.92 0 0 1 4.997 2.62c4.476-1.34 8.75-1.219 12.241 1.1.18.12.357.245.531.376l.6-.8a12.46 12.46 0 0 0-.578-.408C14.008.375 9.443.246 4.71 1.663c-1.037.31-2 .675-2.865 1.06a18.83 18.83 0 0 0-1.103.536Z"/>
                            <path
                                d="m.742 6.748.485.874c.043-.023.13-.07.26-.135.22-.111.476-.233.765-.362A22.92 22.92 0 0 1 4.997 6.11c4.476-1.34 8.75-1.22 12.241 1.1.18.12.357.245.531.375l.6-.8a12.46 12.46 0 0 0-.578-.408C14.008 3.864 9.443 3.735 4.71 5.152c-1.037.31-2 .675-2.865 1.06a18.83 18.83 0 0 0-1.103.536Z"/>
                            <path
                                d="m.742 10.237.485.874c.043-.024.13-.07.26-.136.22-.11.476-.232.765-.36a22.92 22.92 0 0 1 2.745-1.016c4.476-1.34 8.75-1.22 12.241 1.1.18.12.357.244.531.375l.6-.8a12.46 12.46 0 0 0-.578-.408C14.008 7.353 9.443 7.224 4.71 8.64c-1.037.31-2 .674-2.865 1.06a18.83 18.83 0 0 0-1.103.536Z"/>
                        </svg>
                    </button>
                </div>

            </div>
            <div className={classes.content}>
                {props.children}
            </div>
            <div className={classes['menu-wrap']}>
                <div className={classes.tiles}>
                    <div className={classes['tiles__line']}>
                        <div className={MultipleClasses(classes, ['tiles__line-img', 'tiles__line-img--large'])}
                             style={{backgroundImage: `url(${img4})`}}></div>
                        <div className={classes['tiles__line-img']} style={{backgroundImage: `url(${img5})`}}></div>
                        <div className={classes['tiles__line-img']} style={{backgroundImage: `url(${img6})`}}></div>
                        <div className={MultipleClasses(classes, 'tiles__line-img', 'tiles__line-img--large')}
                             style={{backgroundImage: `url(${img4})`}}></div>
                        <div className={classes['tiles__line-img']} style={{backgroundImage: `url(${img5})`}}></div>
                        <div className={classes['tiles__line-img']} style={{backgroundImage: `url(${img6})`}}></div>
                    </div>
                    <div className={classes['tiles__line']}>
                        <div className={classes['tiles__line-img']} style={{backgroundImage: `url(${img1})`}}></div>
                        <div className={classes['tiles__line-img']} style={{backgroundImage: `url(${img2})`}}></div>
                        <div className={MultipleClasses(classes, 'tiles__line-img', 'tiles__line-img--large')}
                             style={{backgroundImage: `url(${img3})`}}></div>
                        <div className={classes['tiles__line-img']} style={{backgroundImage: `url(${img1})`}}></div>
                        <div className={classes['tiles__line-img']} style={{backgroundImage: `url(${img2})`}}></div>
                        <div className={MultipleClasses(classes, 'tiles__line-img', 'tiles__line-img--large')}
                             style={{backgroundImage: `url(${img3})`}}></div>
                    </div>
                    <div className={classes['tiles__line']}>
                        <div className={classes['tiles__line-img']} style={{backgroundImage: `url(${img7})`}}></div>
                        <div className={MultipleClasses(classes, 'tiles__line-img', 'tiles__line-img--large')}
                             style={{backgroundImage: `url(${img8})`}}></div>
                        <div className={classes['tiles__line-img']} style={{backgroundImage: `url(${img9})`}}></div>
                        <div className={classes['tiles__line-img']} style={{backgroundImage: `url(${img7})`}}></div>
                        <div className={MultipleClasses(classes, 'tiles__line-img', 'tiles__line-img--large')}
                             style={{backgroundImage: `url(${img8})`}}></div>
                        <div className={classes['tiles__line-img']} style={{backgroundImage: `url(${img9})`}}></div>
                    </div>
                </div>
                <nav className={classes.menu}>
                    <a className={classes['menu__item']}>
                        <span className={classes['menu__item-tiny']}>get</span>
                        <span className={classes['menu__item-text']}>intouch</span>
                    </a>
                    <a className={classes['menu__item']}>
                        <span className={classes['menu__item-text']}>about</span>
                        <span className={classes['menu__item-tiny']}>me</span>
                    </a>
                    <a className={classes['menu__item']}>
                        <span className={classes['menu__item-tiny']}>start a</span>
                        <span className={classes['menu__item-text']}>dream</span>
                    </a>
                </nav>
                <button onClick={() => setIsMenuOpen(false)} className={MultipleClasses(classes, 'button-close', 'unbutton')}>
                    <svg width="25" height="16" viewBox="0 0 25 16">
                        <path d="M2.238 7.079h2.727M2.482 9.496l-.666-2.7"/>
                        <path d="M23.753 5.403s-1.87 16.88-22.03 1.785"/>
                    </svg>
                </button>
            </div>
            <svg className={classes.overlay} width="100%" height="100%" viewBox="0 0 100 100"
                 preserveAspectRatio="none">


                <path ref={overlayPathRef} className={classes["overlay__path"]} vectorEffect="non-scaling-stroke"
                      d="M 0 100 V 100 Q 50 100 100 100 V 100 z"/>

            </svg>
        </main>
    )
}

export default Menu;