import { useInView } from "react-intersection-observer";

import classes from './DevelopmentDuration.module.css';
import Card from "../../UI/Card";
import CardHeading from "../../UI/CardHeading";

const DevelopmentDuration = () => {
    const { ref, inView, entry } = useInView({
        threshold: .9,

    }); 

    return (
        <Card ref={ref} extraClass={['intro-card--tiny', 'cc-dev-speed']}>
            <CardHeading extraClass={['intro-card-heading--tiny', 'cc-dev-speed']}>Development duration</CardHeading>
            <div className={`${inView ? classes['reveal-in-bottom'] : ''} ${classes['intro-card-dev-speed-wrap']}`} >
                <div className={`${inView ? classes['hide-opacity'] : ''} ${classes['intro-card-dev-speed-months']}`}>
                    <div className={`${inView ? classes['strikethrough'] : ''} ${classes['dev-speed-month-line']}`}/>
                    <div>Months</div>
                </div>
                <div className={`${inView ? classes['reveal-opacity'] : ''} ${classes['dev-speed-weeks']}`}>Weeks</div>
            </div>
        </Card>
    )
}

export default DevelopmentDuration;