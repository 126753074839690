import TypeIt from "typeit-react";

const TypingHeader = () => {
    return (
        <TypeIt
            getBeforeInit={(instance) => {
                instance.type("A")
                        .pause(30)
                        .type(" ")
                        .pause(30)
                        .type("f")
                        .pause(30)
                        .type("r")
                        .pause(30)
                        .type("o")
                        .pause(30)
                        .type("n")
                        .pause(30)
                        .type("t")
                        .pause(30)
                        .type("e")
                        .pause(30)
                        .type("n")
                        .pause(30)
                        .type("d")
                        .pause(30)
                        .type(" ")
                        .pause(224)
                        .type("d")
                        .pause(70)
                        .type("e")
                        .pause(70)
                        .type("v")
                        .pause(200)
                        .delete(1)
                        .pause(30)
                        .delete(1)
                        .pause(30)
                        .delete(1)
                        .pause(30)
                        .delete(1)
                        .pause(30)
                        .delete(1)
                        .pause(30)
                        .delete(1)
                        .pause(30)
                        .delete(1)
                        .pause(30)
                        .delete(1)
                        .pause(30)
                        .delete(1)
                        .pause(30)
                        .delete(1)
                        .pause(30)
                        .delete(1)
                        .pause(30)
                        .delete(1)
                        .pause(30)
                        .type("w")
                        .pause(30)
                        .type("e")
                        .pause(30)
                        .type("b")
                        .pause(30)
                        .type(" ")
                        .pause(30)
                        .type("<em><strong>a</strong></em>")
                        .pause(30)
                        .type("<em><strong>r</strong></em>")
                        .pause(30)
                        .type("<em><strong>t</strong></em>")
                        .pause(30)
                        .type("<em><strong>e</strong></em>")
                        .pause(30)
                        .type("<em><strong>s</strong></em>")
                        .pause(30)
                        .type("<em><strong>i</strong></em>")
                        .pause(30)
                        .type("<em><strong>a</strong></em>")
                        .pause(30)
                        .type("<em><strong>n</strong></em>")
                        .pause(30)
                        .type(".");
                return instance;
            }}
        />
    )
}

export default TypingHeader