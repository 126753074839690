import classes from './FormConsentCheckbox.module.css';

const FormConsentCheckbox = (props) => {
    return (
        <label className={classes['form-consent-label']}>
            <div className={classes['form-custom-checkbox']}/>
            <input type="checkbox" id="consent-checkbox" name="tos-consent" required=""/>
            <span htmlFor="consent-checkbox">I have taken notice of the <a href="#" target="_blank">privacy policy</a> and agree
												that the data of my request will be electronically recorded &amp; stored
												and that I will be contacted by Aley Elfadl.<br/>
            </span>
        </label>
    )
}

export default FormConsentCheckbox;